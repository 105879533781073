import React from "react"
import Layout from "../../components/layout"
import BackBtn from "../../components/BackBtn"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Carousel } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

export default function documentaires({ data }) {
  return (
    <Layout>
      <main className="main-content main-documentaires">
        <div className="title-container">
          <h1 className="main-title">De Straat</h1>
          <BackBtn path="../" text="Terug naar documentaires"></BackBtn>
        </div>
        <p className="main-description"></p>
        <div className="photo-carousel">
          <Carousel>
            {data.allFile.nodes.map(image => {
              return (
                <Carousel.Item key={image.id} interval={5000}>
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt={image.base.split("-").join(" ").split(".")[0]}
                  />
                  <Carousel.Caption>
                    <p className="banner-text">
                      {image.base.split("-").join(" ").split(".")[0]}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>
      </main>
    </Layout>
  )
}

export const getImages = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "documentaires/de-straat" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
        base
        id
      }
    }
  }
`
